import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import {
  BackgroundTexture,
  CalendarIcon,
  HeadingWithIcon,
  Layout,
  SEO,
  SideBySide,
} from '../../components';
import { ContactFormWithMap } from '../../components/contact-form-with-map';
import { useGraphQL } from '../../hooks';

function WhatsOnPage(): React.ReactElement {
  return (
    <Layout>
      <SEO title="What's On" />
      <WantToKnowWhatsOn />
      <Promotions />
      <div className="-mt-24 xs:-mt-0" />
      <ContactFormWithMap isReversed background="grunge" />
    </Layout>
  );
}

function CTA({ heading, route, image }) {
  return (
    <div className="relative items-center justify-center lg:flex bg-blue-dark aspect-w-16 aspect-h-9 lg:aspect-w-3 lg:aspect-h-4">
      <div className="absolute inset-0 flex">
        <GatsbyImage image={image} className="flex-1" alt="" />
      </div>
      <div className="flex flex-col items-center justify-center p-4 bg-black bg-opacity-50">
        <h2 className="text-2xl font-semibold tracking-wide text-center text-white uppercase lg:text-3xl xl:text-4xl">
          {heading}
        </h2>
        <Link
          to={route}
          className="flex items-center justify-center px-4 py-2 mt-6 text-base text-white uppercase transition duration-150 ease-in-out border border-transparent bg-blue-dark"
        >
          {heading === 'Events' ? 'click here' : "who's playing?"}
        </Link>
      </div>
    </div>
  );
}

function WantToKnowWhatsOn(): React.ReactElement {
  const { whatsOnEvents, whatsOnEntertainment } = useGraphQL();
  return (
    <div className="relative py-12">
      <BackgroundTexture overflowBottom insetBottom="full" />
      <div className="relative">
        <SideBySide
          sideComponent={
            <div className="grid h-full gap-8 sm:grid-cols-2">
              <CTA
                heading="Events"
                route="/whats-on/events-entertainment#upcoming_events"
                image={whatsOnEvents.childImageSharp.gatsbyImageData}
              />
              <CTA
                heading="Live Music"
                route="/whats-on/events-entertainment"
                image={whatsOnEntertainment.childImageSharp.gatsbyImageData}
              />
            </div>
          }
          isReversed
        >
          <div className="flex flex-col justify-center w-full mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Want
                <br />
                to know
                <br />
                what’s on?
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <CalendarIcon />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-5 prose">
              <p>
                Weekly fun — bingo, raffles, trivia, live entertainment &amp;
                much more.
              </p>
            </div>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

function Promotions(): React.ReactElement {
  const { whatsOnPromotions } = useGraphQL();
  return (
    <div className="py-12">
      <SideBySide
        isReversed={false}
        sideComponent={
          <GatsbyImage
            image={whatsOnPromotions.childImageSharp.gatsbyImageData}
            className="flex-1 h-full"
            alt=""
          />
        }
      >
        <div className="flex h-full py-8 bg-blue-light text-blue-dark">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <h2 className="heading-2 heading-accent">Promotions</h2>
            <div className="mt-2 prose">
              <p>
                Port City has you covered! Happy Hour, Member’s Only promotions,
                weekly specials at our Drift bar·cafe·restaurant &amp; much more
                are just the beginning at your local club.
              </p>
            </div>
            <p className="mt-4">
              <Link
                to="/whats-on/promotions/"
                className="inline-flex items-center justify-center px-6 button"
              >
                Find out more here
              </Link>
            </p>
          </div>
        </div>
      </SideBySide>
    </div>
  );
}

export { WhatsOnPage as default };
